// components/About.js
import React from 'react';
import '../styles/About.css';

const About = () => {
  
  return (
    <div className="about-page">
      <div className="about-header">
        <div className="about-photo-container">
          <img src="images\CampusST2.jpg" alt="CampusST.jpg" className="about-photo" />
        </div>
        <div className="about-text-container">
          <h2 className="modern-font">About Me</h2>
          <p className={`modern-font justified-text`}>
            <p>Hi!
              <br></br>My name is Alan, I am 26 years old, and I am originally from the south of Mexico City. I started programming at the age 
              of 17 when I was in high school. Since I took a technical degree in Computer Science.
              <br></br>From that class, my passion for programming was born. When I had to choose a career, I chose Computer Engineering because 
              it seemed like a good option to combine my passion for technology with the various work areas in which I could apply my knowledge
              since I believe that the world of the internet and software is in practically everything we do.
            </p>
          </p>
        </div>
        <div className='about-photo-OP-container'>
          <img src="images\mugi.png" alt="Mugiwara" className="about-photo-OP"/>
        </div>
      </div>
      <div className="hobbies-container">
  <div className="hobby-box">
    <h3 className="modern-font">About my professional training</h3>
    <div className="image-text-container">
      <img src="images\miniITAMpr.png" alt="itam" className="inline-image" />
      <p className={`modern-font justified-text`}>
      I studied for 4 years at the Instituto Tecnológico Autónomo de México (ITAM) the degree in Computer Engineering, in which in addition to programming and computing skills, I acquired fundamental knowledge of mathematics, physics, chemistry, economics and philosophy that gave me a broader view of how the world works.
      <br></br> However, I had to suspend my studies due to financial problems with financing, but I have every intention of finishing my degree one day.
      </p>
    </div>
  </div>

  <div className="hobby-box">
    <h3 className="modern-font">My Expectations</h3>
    <div className="image-text-container">
      <img src="images\grow.png" alt="Example" className="inline-image" />
      <p className={`modern-font justified-text`}>
      Professionals: I would like to find a company in which I can grow professionally and in which I can match my values as a person. When you study for a career like mine and see the success of other people like Bill Gates or Mark Zuckerberg, (having the skills), it is impossible not to think about forming a company that is as successful as the ones they founded. I am an ambitious person and I would like to one day create a company with that level of impact.
      <br></br>Personal: I feel young, but time keeps moving forward and I know that one day I will grow old. That is why one of my personal dreams is to 
      start a family and have my own assets.
      <br></br>

      </p>
    </div>
  </div>

  <div className="hobby-box">
    <h3 className="modern-font">Hobbies and other passions</h3>
    <div className="image-text-container">
      <img src="images\universe.png" alt="Example" className="inline-image" />
      <p className={`modern-font justified-text`}>
      My favorite hobbies are: listening to music, watching anime series, playing video games, going for a walk to clear my mind, and swimming as a recreational sport. My favorite anime of all time is One Piece and my favorite games include Halo, Assassin's Creed, and League of Legends.
      <br></br>A topic that I am very passionate about and for which I think I was born very early is space exploration. If I could choose another career, 
      I would choose Astrophysics without a doubt.
      <br></br>I also consider myself a person concerned about the environment and the care of animals.
      </p>
    </div>
  </div>
</div>
    </div>
  );
};

export default About;



