// components/Projects.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Projects.css';

const project1Images = ['/images/p1-lw.png', 
  '/images/p1-mw.png', 
  '/images/p1-cpc.png', 
  '/images/p1-cdc.png', 
  '/images/p1-cdcp.png', 
  '/images/p1-ac.png', 
  '/images/p1-ap.png',
  '/images/p1-apg.png',
];
const project2Images = [
  '/images/p2-lw.png',
  '/images/p2-mw.png',
  '/images/p2-bcw.png',
  '/images/p2-compraE.png',
  '/images/p2-historial-page.png',
];
const project3Images = [
  '/images/p-Medi-LoginP.png',
  '/images/p-Medi-Main.png',
];
const project4Images = [
  'images/cop.png',
  '/images/cres.png'
];

// Custom previous arrow component
const CustomPrevArrow = (props) => (
  <div {...props} className="slick-arrow custom-prev-arrow">
  </div>
);

// Custom next arrow component
const CustomNextArrow = (props) => (
  <div {...props} className="slick-arrow custom-next-arrow">
  </div>
);

const Projects = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const linkStyles = {
    color: 'yellow',
  };
  return (
    <div className="projects-container">
      <h2>Desktop App to manage loans (simulation)</h2>
      <div className="project-slider">
        <Slider {...sliderSettings}>
          {project1Images.map((image, index) => (
            <div key={index} className="image-frame">
              <img
                src={image}
                alt={`Project 1 Image ${index + 1}`}
                onLoad={() => console.log(`Image ${index + 1} loaded successfully`)}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="project-description text-box">
        <p className={`modern-font justified-text`}>
        It was a desktop application created with Visual Studio, C# and Microsoft SQL for the database. The application allowed users only to make inquiries about their loans and payments made, while administrators could register clients, loans and payments, in addition to consulting all the information. The app featured several pop-ups, a main menu, and a login window.
Visual Studio was used to make it easy to create the user interface without having to program everything from scratch.
          <br />
          <br />
          What I learned from developing this application includes creating a relational database, making SQL queries, creating user interfaces from the designer window, and creating a DDL file with classes for performing queries and functions.
        </p>
      </div>
      <div className="project-github">
        <a style={linkStyles} href="https://github.com/Alan-Gallegos/bank-loans-simulator" target="_blank" rel="noopener noreferrer">
          View code on GitHub
        </a>
      </div>
      <h2>Website to buy music (simulation)</h2>
      <div className="project-slider">
        <Slider {...sliderSettings}>
          {project2Images.map((image, index) => (
            <div key={index} className="image-frame">
              <img
                src={image}
                alt={`Project 1 Image ${index + 1}`}
                onLoad={() => console.log(`Image ${index + 1} loaded successfully`)}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="project-description text-box">
        <p className={`modern-font justified-text`}>
          Similar to the desktop app, this project involved developing a website using Microsoft's ASP.NET technology and C# code. The database used was Microsoft SQL Server. The website allowed users to enter, view best-selling songs, search/buy, and check the purchase history.
          <br />
          <br />
          In this project, I learned to keep user sessions open, implement a shopping cart using a dynamic database, and work with ASP.NET technology.
        </p>
      </div>
      <div className="project-github">
        <a style={linkStyles} href="https://github.com/Alan-Gallegos/music-store-simulator" target="_blank" rel="noopener noreferrer">
          View code on GitHub
        </a>
      </div>
      <h2>Private project of an online store of medical products</h2>
      <div className="project-slider">
        <Slider {...sliderSettings}>
          {project3Images.map((image, index) => (
            <div key={index} className="image-frame">
              <img
                src={image}
                alt={`Project 1 Image ${index + 1}`}
                onLoad={() => console.log(`Image ${index + 1} loaded successfully`)}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="project-description text-box">
        <p className={`modern-font justified-text`}>
          A formal project done in conjunction with a fellow programmer. The project consists in an online store so that customers can advertise their medical products. The project is still in its development phase and was planned from scratch. The agile methodology was chosen to progress in it, Jira Software as the medium, react as the library and Oracle SQL along with NodeJs for the database.
        </p>
      </div>
      <div className="project-github">
        <a style={linkStyles} href="https://mediclub.shop" target="_blank" rel="noopener noreferrer">
          Link of Mediclub
        </a>
      </div>
    </div>
  );
};

export default Projects;
