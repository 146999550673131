// components/Skills.js
import React from 'react';
import Carousel from './Carousel';
import '../styles/Skills.css';

const Skills = () => {
  return (
    <div className="skills-container">
      {/* Programming Languages */}
      <div className="section">
        <h2 className="section-title">Programming Languages</h2>
        <div className="image-container">
          <div className="image" id="image1">
            <img className="front" src="images/java-r.png" alt="Java" />
            <img className="back" src="images/l-b-ja (2).png" alt="Java Hover" />
          </div>
          <div className="image" id="image2">
            <img className="front" src="images/js-r.png" alt="JavaScript" />
            <img className="back" src="images/l-b-js (2).png" alt="JavaScript Hover" />
          </div>
          <div className="image" id="image3">
            <img className="front" src="images/c-r.png" alt="C#" />
            <img className="back" src="images/l-b-c.png" alt="C# Hover" />
          </div>
          <div className="image" id="image4">
            <img className="front" src="images/py-r.png" alt="Python" />
            <img className="back" src="images/l-b-p (2).png" alt="Python Hover" />
          </div>
        </div>
      </div>

      {/* Databases and Frameworks */}
      <div className="section">
        <h2 className="section-title">Databases and Frameworks</h2>
        <div className="image-container">
        <div className="image" id="image1">
            <img className="front" src="images/Oracle_SQL.png" alt="Java" />
            <img className="back" src="images/sql-r.png" alt="Java Hover" />
          </div>
          <div className="image" id="image2">
            <img className="front" src="images/net.png" alt="JavaScript" />
            <img className="back" src="images/net-r.png" alt="JavaScript Hover" />
          </div>
          <div className="image" id="image3">
            <img className="front" src="images/react.png" alt="C#" />
            <img className="back" src="images/reac-r.png" alt="C# Hover" />
          </div>
          <div className="image" id="image4">
            <img className="front" src="images/node.png" alt="Python" />
            <img className="back" src="images/node-r.png" alt="Python Hover" />
          </div>
        </div>
      </div>

      {/* Soft Skills */}
      <div className="section">
        <h2 className="section-title">Soft Skills</h2>
        <div className="image-container">
          <div className="image" id="image1">
            <img src="images/CT.png" alt="Creativity" />
          </div>
          <div className="image" id="image2">
            <img src="images/ST.png" alt="Self-taught" />
          </div>
          <div className="image" id="image3">
            <img src="images/TW.png" alt="Teamwork" />
          </div>
          <div className="image" id="image4">
            <img src="images/E-C.png" alt="Effective-com" />
          </div>
          <div className="image" id="image5">
            <img src="images/RS.png" alt="Resilience" />
          </div>
        </div>
      </div>

      {/* Carousel */}
      <div className="section">
        <h2 className="section-title">Tools</h2>

          <Carousel />

      </div>
    </div>
  );
};

export default Skills;
