// components/Contact.js
import React, { useState } from 'react';
import '../styles/Contact.css'; // Create this file for custom contact styles

const Contact = () => {
    const [copiedText, setCopiedText] = useState(null);

    const handleCopyText = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => setCopiedText(text))
        .catch((err) => console.error('Error copying text: ', err));
    }

  return (

    <div className="contact-container">
        <div className="contact-label">
            <h1>Contact me by these means:</h1>
            <p className={`modern-font justified-text`}> You can click on the images or copy the link</p>
        </div>
      <div className="contact-grid">
        <div className="contact-item">
        <a href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWrRmTNtFtQwdHhmLQVGggwSGzRtSTsvzfHvJmzfJdJCvTSMDgZMhNBfQNQVCMskpqtstmNDV">
        <img src="images\e-mail.png" alt="Email" />
        </a>
        <p>Email: gallegos.villa.alan@gmail.com</p>
        <button onClick={() => handleCopyText('gallegos.villa.alan@gmail.com')}>
            {copiedText === 'gallegos.villa.alan@gmail.com' ? 'Copied!' : 'Copy'}
          </button>
        </div>
        <div className="contact-item">
        <img src="images\ws.png" alt="WhatsApp" />
          <p>Phone: +52 (55) 4595-8094</p>
          <button onClick={() => handleCopyText('+52 (55) 4595-8094')}>
            {copiedText === '+52 (55) 4595-8094' ? 'Copied!' : 'Copy'}
          </button>
        </div>
        <div className="contact-item">
        <a href="https://github.com/Alan-Gallegos">
        <img src="images\gh.jpg" alt="GitHub" />
        </a>
        <p>GitHub: https://github.com/Alan-Gallegos</p>
        <button onClick={() => handleCopyText('https://github.com/Alan-Gallegos')}>
            {copiedText === 'https://github.com/Alan-Gallegos' ? 'Copied!' : 'Copy'}
          </button>
        </div>
        <div className="contact-item">
        <a href="https://www.linkedin.com/in/alangallegos97">
        <img src="images\linkedin.png" alt="Linkedin" />
        </a>
        <p>Linkedin: www.linkedin.com/in/alangallegos97</p>
        <button onClick={() => handleCopyText('www.linkedin.com/in/alangallegos97')}>
            {copiedText === 'www.linkedin.com/in/alangallegos97' ? 'Copied!' : 'Copy'}
          </button>
        </div>
        </div>
    </div>
  );
};

export default Contact;
