// components/Carousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import '../styles/Carousel.css'; // Create this file for custom carousel styles

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of slides to show in a row
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the interval between slides
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div>
          <img src="images\eclipse-logo.png" alt="Image 1" className="carousel-image" />
        </div>
        <div>
          <img src="images\netbeans.png" alt="Image 2" className="carousel-image" />
        </div>
        <div>
            <img src="images\vs.png" alt="Image 3" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\vsc.png" alt="Image 4" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\msql.png" alt="Image 5" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\oracle.png" alt="Image 6" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\excel.png" alt="Image 7" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\ghl.jpg" alt="Image 8" className="carousel-image"/> 
        </div>
        <div>
            <img src="images\git.png" alt="Image 9" className="carousel-image"/> 
        </div>
        {/* Add more image elements for additional slides */}
      </Slider>
    </div>
  );
};

export default Carousel;
