// Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navigation.css'; // Import the styles

const Navigation = ({ nightMode, spanishMode }) => {
  return (
    <nav className={nightMode ? 'night-mode' : ''}>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About Me</Link></li>
        <li><Link to="/skills">Skills</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;