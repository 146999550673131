// pages/Home.js
import React from 'react';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className="container">
      <div className="text-box">
        <h1>Welcome to My Portfolio!</h1>
        <p className='p-home'>
        Hello future employer, on this site you can find brief information about me, the programming languages that I know, IDEs, and tools I use, 
        as well as the level of mastery I have over them. You will also find some projects I have developed, the technologies I used and a link 
        to my GitHub repository. You can browse the menu above to visit the sections I mentioned.
<br></br>Thank you for your visit!
        </p>
        {/* Other home content */}
      </div>
    </div>
  );
};

export default Home;

